/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef,useEffect,useState } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'
import {useThree} from "@react-three/fiber";


const deg2rad = degrees => degrees * (Math.PI / 180);
var nonStateAxis = [0,0]


export default function Model({ ...props }) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/Dynamic.glb')
  const { actions } = useAnimations(animations, group)

  useThree(({camera})=>{
    camera.rotation.set(deg2rad(31),0,0)
  })

  const [xAxis,setXAxis] = useState(0)
  const [yAxis,setYAxis] = useState(2.22)

  function checkAxis(e){
    let x = ((e.clientX/window.innerWidth*3)-1.5)*-1
    let y = (e.clientY/window.innerHeight/2)+2

    const X_ERROR = .1
    const Y_ERROR = .02

    if(Math.abs(nonStateAxis[0] - x) > X_ERROR){
      nonStateAxis[0] = x
      setXAxis(nonStateAxis[0])
    }

    if(Math.abs(nonStateAxis[1] - y) > Y_ERROR){
      nonStateAxis[1] = y
      setYAxis(nonStateAxis[1])
    }
  }

  useEffect(()=>{
    let action_name = Object.keys(actions)[0]
    actions[action_name].play()

    document.getElementById("root").addEventListener("mousemove",checkAxis)
  },[actions])

  useEffect( () => () => {
    document.getElementById("root").removeEventListener("mousemove",checkAxis)
  }, [] );

  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Armature" rotation={[yAxis, 0, xAxis]} scale={.8}>
        <primitive object={nodes.mixamorigHips} />
        <skinnedMesh
          geometry={nodes.Mesh_Cube.geometry}
          material={materials.some_mats}
          skeleton={nodes.Mesh_Cube.skeleton}
        />
      </group>
    </group>
  )
}

useGLTF.preload('/Dynamic.glb')
