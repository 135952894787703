import "./ProjectsSVG.scss"

export default function ProjectsSVG(){
    return(<>
       <div className="svg-anim" id="projects-svg">
           <svg width="1261" height="404" viewBox="0 0 1261 404" fill="none" xmlns="http://www.w3.org/2000/svg">
               <g id="projects-anim" clipPath="url(#clip0_27_69)">
                   <path id="sun" d="M611.954 87.1058C625.176 87.1058 635.894 76.3877 635.894 63.1662C635.894 49.9447 625.176 39.2266 611.954 39.2266C598.733 39.2266 588.015 49.9447 588.015 63.1662C588.015 76.3877 598.733 87.1058 611.954 87.1058Z" fill="#FF6584"/>
                   <path id="right_cloud" d="M685.252 61.9169C683.095 59.6381 680.818 57.4763 678.43 55.4409C673.766 51.486 668.603 48.1231 662.848 46.0047C660.681 45.2045 658.442 44.6151 656.162 44.2447C655.888 44.2004 655.619 44.1561 655.345 44.1198C655.325 44.1178 655.304 44.1178 655.284 44.1198C655.242 44.1079 655.199 44.0998 655.155 44.0957C654.596 44.0232 654.036 43.9668 653.472 43.9265C650.436 43.7247 647.386 43.9992 644.435 44.7401C642.722 45.1721 641.051 45.7543 639.441 46.4799C637.827 47.2697 636.263 48.156 634.757 49.1339C633.948 49.6254 633.114 50.0757 632.26 50.4831C629.765 51.6652 627.124 52.5103 624.406 52.9962C622.571 53.3255 620.713 53.5086 618.848 53.544C617.229 53.5762 615.703 53.6325 614.169 54.2165C612.531 54.8318 611.06 55.8217 609.873 57.107C608.687 58.3924 607.817 59.9375 607.334 61.6189C607.296 61.7682 607.307 61.9258 607.365 62.0686C607.423 62.2113 607.525 62.3318 607.656 62.4123C608.071 62.7103 608.474 63.0486 608.913 63.3144C609.3 63.4912 609.714 63.6041 610.137 63.6487C610.83 63.7937 611.524 63.9347 612.219 64.0716C614.777 64.579 617.345 65.0287 619.924 65.4208C625.111 66.2061 630.328 66.7592 635.574 67.08C635.606 67.0847 635.639 67.0874 635.671 67.0881C641.598 67.4458 647.539 67.5036 653.472 67.2613C654.37 67.225 655.264 67.1807 656.162 67.1284C659.904 66.9189 663.636 66.5887 667.358 66.1376C670.802 65.7188 674.232 65.1992 677.649 64.579C680.089 64.136 682.52 63.642 684.942 63.0969C685.06 63.0621 685.166 62.9976 685.252 62.9095C685.337 62.8215 685.398 62.7129 685.43 62.5943C685.461 62.4757 685.461 62.351 685.43 62.2323C685.399 62.1137 685.338 62.005 685.252 61.9169V61.9169Z" fill="#D8F0EE"/>
                   <path id="left_cloud" d="M635.494 93.8381C632.028 90.1306 628.21 86.7693 624.092 83.8018C622.727 82.8433 621.321 81.9412 619.876 81.1155C618.103 80.0923 616.262 79.1921 614.366 78.4212C613.943 78.248 613.516 78.0829 613.089 77.9258C610.664 77.0275 608.148 76.3955 605.586 76.041C605.566 76.037 605.546 76.041 605.526 76.037C605.486 76.0289 605.441 76.0209 605.401 76.0128C604.159 75.8518 602.908 75.7751 601.656 75.7832C600.758 75.7832 599.855 75.8316 598.961 75.9202C597.517 76.0625 596.085 76.3102 594.676 76.6612C593.979 76.8344 593.287 77.0358 592.606 77.2653C591.613 77.592 590.639 77.9711 589.686 78.4011C589.67 78.4091 589.658 78.4132 589.642 78.4212C588.043 79.2034 586.494 80.0831 585.002 81.0551C584.97 81.0753 584.934 81.0954 584.902 81.1155C583.208 82.1287 581.416 82.968 579.553 83.6206C577.954 84.1797 576.314 84.6133 574.648 84.9174C572.813 85.2475 570.954 85.4293 569.09 85.4611C567.471 85.4933 565.945 85.5497 564.414 86.1377C562.776 86.7529 561.305 87.7427 560.117 89.0279C558.93 90.3132 558.06 91.8584 557.576 93.5401C557.539 93.6894 557.55 93.8467 557.608 93.9893C557.665 94.1319 557.767 94.2524 557.898 94.3334C558.313 94.6315 558.72 94.9658 559.155 95.2316C559.541 95.4097 559.955 95.5226 560.379 95.5658C561.072 95.7108 561.764 95.8558 562.461 95.9927C565.018 96.5002 567.588 96.9486 570.17 97.3379C573.71 97.8735 577.264 98.3018 580.834 98.6226C582.526 98.7797 584.218 98.9086 585.913 99.0093C590.258 99.271 594.608 99.373 598.961 99.3153C599.86 99.3033 600.758 99.2831 601.656 99.2549C606.988 99.0979 612.304 98.6978 617.604 98.0548C623.512 97.34 629.379 96.3252 635.184 95.0141C635.301 94.9797 635.408 94.9155 635.493 94.8278C635.578 94.7402 635.64 94.6319 635.671 94.5136C635.702 94.3953 635.702 94.271 635.671 94.1526C635.64 94.0342 635.579 93.9259 635.494 93.8381V93.8381Z" fill="#D8F0EE"/>
                   <g id="bg">
                       <path id="Vector" d="M778.959 125.498C775.556 119.36 771.781 113.435 767.654 107.758C764.762 103.774 761.707 99.9243 758.488 96.207H758.479C752.654 89.4777 746.319 83.2072 739.531 77.4514C738.141 76.2714 736.733 75.1155 735.306 73.9839C722.425 63.7363 708.14 55.3895 692.889 49.1986L693.024 71.4854L694.483 325.542H567.991L560.878 71.9648L561.408 50.0339C499.392 76.1161 455.849 137.44 455.849 208.938C455.837 223.695 457.72 238.393 461.451 252.671C463.027 258.707 464.935 264.651 467.166 270.477C467.971 272.591 468.821 274.687 469.715 276.763C470.146 277.774 470.585 278.777 471.04 279.776C471.624 281.081 472.232 282.382 472.856 283.666C473.34 284.677 473.836 285.681 474.347 286.679C475.003 287.988 475.681 289.286 476.38 290.573C476.92 291.584 477.476 292.587 478.04 293.586C487.136 309.676 498.752 324.204 512.446 336.619C516.348 340.159 520.409 343.515 524.629 346.687C554.483 369.121 590.817 381.25 628.161 381.25C665.505 381.25 701.839 369.121 731.693 346.687C733.989 344.963 736.237 343.182 738.439 341.343C739.901 340.126 741.339 338.887 742.752 337.625C743.127 337.291 743.502 336.957 743.872 336.619C756.497 325.174 767.362 311.927 776.115 297.307C779.33 291.947 782.245 286.413 784.847 280.73C785.536 279.232 786.203 277.721 786.848 276.195C789.821 269.203 792.321 262.02 794.331 254.693C794.642 253.562 794.941 252.426 795.229 251.286V251.282C795.278 251.085 795.33 250.883 795.378 250.686C795.677 249.486 795.962 248.278 796.236 247.069V247.065C797.122 243.171 797.87 239.229 798.48 235.241C798.556 234.729 798.633 234.218 798.705 233.706C798.733 233.521 798.762 233.336 798.782 233.151V233.147C799.217 230.066 799.57 226.962 799.841 223.835C800.26 218.926 800.47 213.96 800.473 208.938C800.519 179.747 793.114 151.028 778.959 125.498V125.498Z" fill="#D8F0EE"/>
                       <path id="Vector_2" d="M575.822 29.5894L596.913 321.036L554.732 324.78V33.2974C559.758 29.3713 566.973 28.7571 575.822 29.5894Z" fill="#6D7A79"/>
                       <path id="Vector_3" d="M679.927 29.5894L658.837 321.036L701.018 324.78V33.2974C695.991 29.3713 688.776 28.7571 679.927 29.5894Z" fill="#6D7A79"/>
                       <path id="Vector_4" d="M702.01 33.4246C700.701 30.2155 697.918 28 694.7 28H560.6C556.126 28 552.489 32.2931 552.489 37.5751V327.249C552.45 329.929 553.452 332.519 555.284 334.475C556.243 335.482 557.456 336.21 558.796 336.582C559.383 336.744 559.99 336.826 560.6 336.824H694.7C695.31 336.826 695.917 336.744 696.505 336.582C700.113 335.612 702.812 331.799 702.812 327.249V37.5751C702.815 36.1528 702.543 34.7433 702.01 33.4246V33.4246ZM700.121 327.249C700.121 330.777 697.689 333.648 694.7 333.648H560.6C558.123 333.648 556.025 331.675 555.385 328.989C555.248 328.419 555.179 327.835 555.179 327.249V37.5751C555.179 34.0474 557.612 31.1806 560.6 31.1806H694.7C695.25 31.1802 695.796 31.2784 696.311 31.4706C698.514 32.2789 700.121 34.7083 700.121 37.5751V327.249Z" fill="#6D7A79"/>
                       <path id="Vector_5" d="M701.466 116.051H553.835V118.743H701.466V116.051Z" fill="#6D7A79"/>
                       <path id="Vector_6" d="M701.466 60.4727H553.835V63.165H701.466V60.4727Z" fill="#6D7A79"/>
                       <path id="Vector_7" d="M701.466 160.924H553.835V163.616H701.466V160.924Z" fill="#6D7A79"/>
                       <path id="Vector_8" d="M601.743 29.2082H599.05V199.066H601.743V29.2082Z" fill="#6D7A79"/>
                       <path id="Vector_9" d="M656.251 29.2082H653.559V199.066H656.251V29.2082Z" fill="#6D7A79"/>
                       <path id="Vector_10" d="M718.293 319.998H541.494C538.283 320.001 535.203 321.279 532.932 323.55C530.661 325.821 529.384 328.9 529.38 332.112C529.378 333.656 529.675 335.187 530.254 336.619C531.153 338.865 532.705 340.79 534.708 342.147C536.711 343.503 539.075 344.229 541.494 344.23H718.293C720.408 344.23 722.486 343.675 724.32 342.623C726.154 341.57 727.681 340.055 728.748 338.23C729.052 337.714 729.316 337.175 729.538 336.619C730.116 335.187 730.413 333.656 730.411 332.112C730.407 328.9 729.128 325.821 726.857 323.55C724.585 321.279 721.505 320.001 718.293 319.998Z" fill="#6D7A79"/>
                       <path id="Vector_11" d="M701.466 192.783H554.732V201.758H701.466V192.783Z" fill="#6D7A79"/>
                       <path id="Vector_12" d="M626.753 190.091H629.446C630.755 190.091 632.01 190.611 632.936 191.537C633.861 192.462 634.382 193.718 634.382 195.027V195.027H621.817C621.817 194.379 621.945 193.737 622.193 193.138C622.441 192.539 622.805 191.995 623.263 191.537C623.721 191.078 624.265 190.715 624.864 190.467C625.463 190.219 626.105 190.091 626.753 190.091V190.091Z" fill="#6D7A79"/>
                   </g>
                   <g id="right_leaves">
                       <path id="Vector_13" d="M761.037 312.953C771.236 299.172 775.158 279.761 767.224 264.563C759.784 281.658 746.537 295.57 729.826 303.838C723.37 307.004 715.86 309.852 712.871 316.392C711.012 320.461 711.366 325.266 712.861 329.483C714.356 333.699 716.898 337.452 719.414 341.151L719.546 342.26C735.383 335.694 750.838 326.734 761.037 312.953Z" fill="#E6E6E6"/>
                       <path id="Vector_14" d="M766.886 264.685C766.429 278.869 761.515 292.547 752.841 303.779C751.017 306.23 748.817 308.378 746.324 310.144C743.84 311.786 740.961 312.733 737.987 312.886C735.217 313.084 732.36 312.966 729.687 313.835C728.325 314.3 727.077 315.047 726.024 316.028C724.971 317.009 724.138 318.202 723.578 319.528C722.098 322.795 721.88 326.399 721.789 329.932C721.688 333.853 721.646 337.928 719.796 341.494C719.572 341.925 720.255 342.248 720.479 341.817C723.698 335.614 721.608 328.361 723.526 321.84C724.421 318.798 726.238 316.007 729.276 314.787C731.932 313.721 734.877 313.84 737.684 313.663C740.587 313.556 743.42 312.74 745.936 311.286C748.511 309.653 750.789 307.593 752.673 305.195C757.001 299.864 760.463 293.886 762.934 287.48C765.794 280.184 767.386 272.452 767.639 264.62C767.654 264.135 766.901 264.204 766.886 264.685V264.685Z" fill="white"/>
                       <path id="Vector_15" d="M755.847 300.327C757.929 301.526 760.369 301.946 762.733 301.513C765.096 301.079 767.228 299.819 768.748 297.958C769.054 297.581 768.482 297.087 768.175 297.465C766.763 299.2 764.777 300.374 762.575 300.773C760.374 301.173 758.102 300.772 756.17 299.644C755.75 299.399 755.429 300.083 755.847 300.327Z" fill="white"/>
                       <path id="Vector_16" d="M739.405 312.97C738.087 308.239 738.601 303.184 740.844 298.815C741.066 298.382 740.383 298.059 740.161 298.491C737.835 303.043 737.311 308.304 738.693 313.225C738.825 313.693 739.536 313.436 739.405 312.97H739.405Z" fill="white"/>
                       <path id="Vector_17" d="M764.793 280.219C763.891 279.922 763.077 279.407 762.423 278.718C761.769 278.029 761.297 277.189 761.048 276.272C760.922 275.802 760.212 276.059 760.337 276.527C760.616 277.538 761.139 278.465 761.86 279.228C762.58 279.991 763.476 280.565 764.47 280.902C764.561 280.941 764.664 280.945 764.757 280.911C764.851 280.878 764.928 280.81 764.973 280.722C765.016 280.631 765.021 280.527 764.987 280.433C764.953 280.339 764.884 280.262 764.793 280.219V280.219Z" fill="white"/>
                       <path id="Vector_18" d="M710.451 252.469C710.522 252.764 710.592 253.06 710.658 253.361C711.584 257.345 712.184 261.397 712.453 265.478C712.479 265.793 712.5 266.113 712.517 266.428C713.061 276.267 711.773 286.121 708.719 295.489C707.501 299.216 706.001 302.845 704.232 306.344C701.788 311.177 698.755 316.222 698.07 321.426C697.993 321.968 697.949 322.515 697.939 323.062L718.183 342.336C718.251 342.357 718.315 342.382 718.383 342.403L719.156 343.21C719.26 343.036 719.364 342.853 719.468 342.679C719.528 342.578 719.585 342.473 719.645 342.372C719.684 342.304 719.723 342.235 719.762 342.175C719.775 342.152 719.788 342.13 719.797 342.111C719.836 342.052 719.866 341.992 719.901 341.937C720.485 340.917 721.062 339.891 721.634 338.859C721.638 338.854 721.638 338.854 721.638 338.845C725.974 330.971 729.69 322.666 732.027 314.061C732.097 313.802 732.172 313.539 732.233 313.271C733.265 309.386 733.978 305.424 734.364 301.423C734.569 299.232 734.663 297.031 734.645 294.83C734.587 289.144 733.636 283.502 731.826 278.111C728.215 267.364 721.207 257.893 711.218 252.846C710.963 252.718 710.711 252.593 710.451 252.469Z" fill="#E6E6E6"/>
                       <path id="Vector_19" d="M710.252 252.769C718.426 264.368 722.738 278.249 722.575 292.439C722.594 295.494 722.131 298.533 721.204 301.445C720.209 304.251 718.48 306.74 716.198 308.653C714.106 310.479 711.754 312.105 710.142 314.408C709.334 315.599 708.788 316.948 708.538 318.365C708.288 319.782 708.341 321.236 708.692 322.632C709.477 326.132 711.473 329.141 713.528 332.016C715.808 335.208 718.228 338.487 718.897 342.447C718.978 342.927 719.718 342.773 719.637 342.294C718.473 335.404 712.437 330.871 710.043 324.51C708.925 321.541 708.696 318.219 710.387 315.416C711.866 312.966 714.289 311.287 716.424 309.457C718.678 307.623 720.448 305.266 721.581 302.59C722.654 299.736 723.233 296.719 723.293 293.67C723.539 286.809 722.705 279.951 720.82 273.348C718.712 265.8 715.327 258.669 710.814 252.263C710.534 251.867 709.974 252.375 710.252 252.769V252.769Z" fill="white"/>
                       <path id="Vector_20" d="M722.896 287.873C725.281 287.577 727.482 286.443 729.108 284.674C730.734 282.905 731.678 280.615 731.771 278.215C731.788 277.729 731.034 277.679 731.017 278.166C730.934 280.401 730.055 282.534 728.537 284.178C727.02 285.823 724.965 286.871 722.743 287.133C722.26 287.19 722.416 287.93 722.896 287.873H722.896Z" fill="white"/>
                       <path id="Vector_21" d="M717.381 307.867C713.48 304.883 710.847 300.538 710.007 295.698C709.924 295.219 709.184 295.372 709.267 295.851C710.15 300.886 712.899 305.402 716.966 308.498C717.354 308.793 717.766 308.16 717.381 307.867V307.867Z" fill="white"/>
                       <path id="Vector_22" d="M717.933 266.431C717.034 266.738 716.074 266.817 715.137 266.66C714.2 266.504 713.317 266.117 712.567 265.535C712.183 265.235 711.771 265.869 712.152 266.167C712.984 266.806 713.96 267.232 714.994 267.407C716.029 267.582 717.09 267.501 718.086 267.171C718.183 267.148 718.267 267.089 718.321 267.006C718.376 266.923 718.397 266.822 718.38 266.725C718.359 266.627 718.3 266.541 718.217 266.486C718.133 266.431 718.031 266.411 717.933 266.431Z" fill="white"/>
                   </g>
                   <g id="left_leaves">
                       <path id="Vector_23" d="M444.044 235.771C434.999 221.207 432.667 201.541 441.809 187.038C447.836 204.681 459.909 219.624 475.892 229.223C482.07 232.902 489.324 236.351 491.771 243.112C493.293 247.319 492.55 252.079 490.717 256.161C488.885 260.242 486.046 263.775 483.238 267.258L483.016 268.353C467.765 260.521 453.09 250.335 444.044 235.771Z" fill="#E6E6E6"/>
                       <path id="Vector_24" d="M442.137 187.187C441.44 201.361 445.226 215.394 452.958 227.293C454.577 229.884 456.595 232.204 458.936 234.166C461.279 236.005 464.071 237.182 467.023 237.577C469.768 238 472.624 238.115 475.218 239.197C476.538 239.771 477.722 240.618 478.691 241.681C479.661 242.745 480.395 244.001 480.844 245.368C482.054 248.745 481.979 252.355 481.782 255.883C481.563 259.8 481.275 263.865 482.829 267.568C483.017 268.017 482.31 268.283 482.122 267.835C479.418 261.391 482.09 254.332 480.709 247.677C480.064 244.572 478.479 241.642 475.551 240.18C472.99 238.902 470.045 238.781 467.262 238.376C464.377 238.034 461.619 236.99 459.231 235.336C456.797 233.499 454.693 231.261 453.01 228.718C449.13 223.053 446.165 216.813 444.223 210.227C441.965 202.723 441.008 194.888 441.392 187.061C441.416 186.576 442.161 186.706 442.137 187.187L442.137 187.187Z" fill="white"/>
                       <path id="Vector_25" d="M450.243 223.608C448.071 224.634 445.604 224.855 443.284 224.23C440.964 223.606 438.941 222.177 437.577 220.199C437.303 219.798 437.913 219.352 438.188 219.754C439.455 221.598 441.339 222.929 443.501 223.506C445.662 224.083 447.959 223.868 449.977 222.901C450.415 222.691 450.679 223.399 450.243 223.608V223.608Z" fill="white"/>
                       <path id="Vector_26" d="M465.603 237.546C467.301 232.937 467.2 227.857 465.319 223.32C465.132 222.871 465.84 222.605 466.026 223.053C467.974 227.779 468.069 233.065 466.292 237.857C466.122 238.314 465.434 238 465.603 237.546V237.546Z" fill="white"/>
                       <path id="Vector_27" d="M442.96 202.839C443.884 202.617 444.737 202.17 445.445 201.536C446.152 200.903 446.691 200.103 447.014 199.21C447.178 198.752 447.865 199.066 447.702 199.522C447.342 200.507 446.745 201.389 445.965 202.09C445.185 202.792 444.245 203.292 443.227 203.546C443.133 203.578 443.03 203.573 442.94 203.533C442.849 203.492 442.778 203.418 442.74 203.326C442.705 203.232 442.709 203.129 442.75 203.037C442.791 202.946 442.867 202.875 442.96 202.839V202.839Z" fill="white"/>
                       <path id="Vector_28" d="M499.378 179.597C499.284 179.886 499.19 180.176 499.099 180.47C497.853 184.365 496.925 188.355 496.325 192.401C496.274 192.713 496.227 193.03 496.185 193.343C494.843 203.105 495.326 213.031 497.608 222.617C498.519 226.43 499.72 230.169 501.198 233.8C503.241 238.815 505.855 244.09 506.114 249.333C506.147 249.88 506.146 250.428 506.111 250.974L484.368 268.539C484.299 268.554 484.233 268.574 484.163 268.59L483.327 269.331C483.238 269.149 483.149 268.958 483.06 268.776C483.008 268.671 482.96 268.562 482.908 268.456C482.875 268.385 482.842 268.313 482.807 268.251C482.796 268.227 482.785 268.203 482.778 268.184C482.744 268.121 482.719 268.06 482.689 268.002C482.19 266.938 481.697 265.868 481.211 264.793C481.207 264.788 481.207 264.788 481.208 264.779C477.527 256.579 474.498 247.999 472.868 239.233C472.819 238.969 472.766 238.7 472.727 238.428C472.014 234.473 471.625 230.465 471.565 226.446C471.539 224.246 471.625 222.045 471.822 219.853C472.341 214.19 473.748 208.644 475.99 203.418C480.463 193 488.217 184.129 498.583 179.912C498.848 179.804 499.109 179.7 499.378 179.597Z" fill="#E6E6E6"/>
                       <path id="Vector_29" d="M499.552 179.913C490.462 190.81 485.036 204.294 484.046 218.45C483.779 221.494 483.993 224.56 484.681 227.537C485.444 230.416 486.965 233.037 489.084 235.129C491.021 237.119 493.233 238.931 494.652 241.357C495.36 242.61 495.796 243.998 495.93 245.431C496.063 246.864 495.893 248.309 495.429 249.671C494.362 253.096 492.128 255.933 489.847 258.631C487.314 261.627 484.636 264.699 483.647 268.592C483.527 269.063 482.803 268.85 482.922 268.379C484.643 261.606 491.027 257.579 493.93 251.433C495.285 248.566 495.784 245.273 494.326 242.342C493.051 239.78 490.772 237.91 488.794 235.911C486.696 233.901 485.123 231.407 484.211 228.649C483.374 225.716 483.042 222.663 483.23 219.619C483.542 212.76 484.931 205.993 487.346 199.565C490.061 192.214 494.014 185.381 499.033 179.363C499.344 178.991 499.861 179.543 499.552 179.913V179.913Z" fill="white"/>
                       <path id="Vector_30" d="M484.097 213.873C481.744 213.384 479.642 212.076 478.165 210.18C476.689 208.285 475.934 205.926 476.036 203.525C476.041 203.428 476.084 203.335 476.155 203.269C476.227 203.202 476.322 203.165 476.42 203.167C476.518 203.168 476.611 203.208 476.681 203.277C476.75 203.346 476.79 203.44 476.792 203.538C476.693 205.773 477.396 207.97 478.774 209.732C480.153 211.495 482.116 212.706 484.309 213.148C484.786 213.245 484.57 213.969 484.097 213.873V213.873Z" fill="white"/>
                       <path id="Vector_31" d="M487.969 234.249C492.1 231.592 495.077 227.475 496.307 222.72C496.429 222.249 497.154 222.462 497.032 222.933C495.743 227.879 492.636 232.156 488.331 234.912C487.921 235.175 487.561 234.51 487.969 234.249V234.249Z" fill="white"/>
                       <path id="Vector_32" d="M490.786 192.906C491.657 193.284 492.608 193.441 493.554 193.361C494.501 193.281 495.412 192.968 496.207 192.449C496.614 192.181 496.974 192.846 496.569 193.112C495.688 193.681 494.681 194.026 493.636 194.117C492.591 194.207 491.539 194.04 490.573 193.631C490.479 193.6 490.4 193.534 490.352 193.447C490.305 193.36 490.292 193.258 490.317 193.162C490.346 193.066 490.411 192.985 490.499 192.937C490.587 192.889 490.69 192.878 490.786 192.906V192.906Z" fill="white"/>
                       <path id="Vector_33" d="M511.083 248.588H463.318C462.986 248.588 462.667 248.72 462.431 248.955C462.196 249.189 462.063 249.508 462.062 249.84V252.671C463.638 258.707 465.545 264.651 467.777 270.477H511.083C511.248 270.477 511.411 270.444 511.563 270.382C511.715 270.319 511.853 270.226 511.969 270.11C512.086 269.994 512.178 269.856 512.241 269.704C512.304 269.552 512.336 269.389 512.336 269.224V249.84C512.335 249.508 512.203 249.19 511.969 248.955C511.734 248.72 511.415 248.588 511.083 248.588V248.588Z" fill="#056D65"/>
                   </g>
                   <path id="person" d="M555.066 342.257H708.881C707.853 330.524 707.181 285.674 707.181 282.722C707.181 269.702 696.528 255.494 696.528 255.494L692.715 253.387L660.87 243.451L652.629 232.746C651.766 231.622 650.654 230.713 649.38 230.091C648.107 229.47 646.706 229.152 645.289 229.163L619.256 229.358C616.965 229.375 614.763 230.248 613.082 231.804L596.961 246.759L573.317 258.05L573.277 258.01L573.03 258.193L569.521 260.72L567.264 262.351C567.264 262.351 561.343 269.456 556.611 290.762C555.899 293.967 555.399 335.451 555.066 342.257Z" fill="#056D65"/>
                   <path id="table" d="M743.483 336.619C743.113 336.957 742.738 337.291 742.363 337.625C740.946 338.886 739.508 340.125 738.05 341.343C735.847 343.179 733.598 344.961 731.304 346.687H524.24C520.023 343.522 515.962 340.165 512.057 336.619H743.483Z" fill="#6D7A79"/>
                   <path id="cup" d="M737.447 306.01H717.64C717.193 306.009 716.751 306.106 716.346 306.294C715.94 306.481 715.581 306.756 715.292 307.098C715.016 307.044 714.736 307.018 714.455 307.017C713.837 307.017 713.226 307.138 712.656 307.374C712.085 307.609 711.567 307.955 711.13 308.392C710.693 308.828 710.347 309.346 710.11 309.916C709.874 310.487 709.752 311.098 709.752 311.715C709.752 312.333 709.874 312.944 710.11 313.514C710.347 314.084 710.693 314.602 711.13 315.039C711.567 315.475 712.085 315.821 712.656 316.057C713.226 316.293 713.837 316.414 714.455 316.413C715.164 316.413 715.864 316.25 716.501 315.938L716.831 319.998L718.144 336.043C718.206 336.243 718.29 336.436 718.394 336.619C718.659 337.106 719.051 337.513 719.528 337.796C720.005 338.08 720.549 338.229 721.104 338.23H733.984C734.539 338.229 735.084 338.079 735.561 337.796C736.039 337.512 736.431 337.106 736.698 336.619C736.799 336.435 736.881 336.242 736.944 336.043L740.407 310.018C740.55 309.554 740.581 309.064 740.5 308.587C740.419 308.109 740.226 307.657 739.938 307.267C739.65 306.877 739.275 306.561 738.842 306.342C738.41 306.124 737.932 306.01 737.447 306.01ZM714.455 315.072C713.565 315.071 712.712 314.717 712.084 314.087C711.455 313.458 711.102 312.605 711.102 311.715C711.102 310.826 711.455 309.972 712.084 309.343C712.712 308.713 713.565 308.359 714.455 308.358C714.515 308.358 714.572 308.374 714.632 308.379C714.499 308.919 714.516 309.486 714.68 310.018L716.098 314.625C715.599 314.915 715.032 315.069 714.455 315.072V315.072Z" fill="#3F3D56"/>
                   <g id="monitor">
                       <path id="Vector_34" d="M692.711 220.859H567.33C566.253 220.861 565.22 221.289 564.458 222.051C563.697 222.813 563.268 223.845 563.266 224.923V309.53C563.267 310.608 563.696 311.641 564.458 312.403C565.22 313.164 566.253 313.593 567.33 313.594H692.711C693.788 313.593 694.821 313.164 695.583 312.403C696.345 311.641 696.774 310.608 696.775 309.53V224.923C696.773 223.845 696.344 222.813 695.583 222.051C694.821 221.289 693.788 220.861 692.711 220.859Z" fill="#3F3D56"/>
                       <path id="Vector_35" d="M644.141 288.652H614.809C614.394 288.653 613.996 288.818 613.703 289.112C613.41 289.406 613.246 289.804 613.246 290.219V336.554H645.707V290.219C645.707 290.013 645.667 289.809 645.588 289.619C645.509 289.429 645.394 289.256 645.249 289.111C645.103 288.965 644.93 288.85 644.74 288.771C644.55 288.693 644.346 288.652 644.141 288.652V288.652ZM629.573 314.979C628.656 314.975 627.778 314.609 627.129 313.96C626.48 313.312 626.114 312.433 626.11 311.516V306.171C626.111 305.254 626.476 304.374 627.126 303.725C627.775 303.076 628.655 302.712 629.573 302.712C630.491 302.712 631.372 303.076 632.021 303.725C632.671 304.374 633.036 305.254 633.037 306.171V311.516C633.033 312.433 632.666 313.312 632.018 313.96C631.369 314.609 630.491 314.975 629.573 314.979V314.979Z" fill="#2F2E41"/>
                       <path id="Vector_36" d="M613.146 335.072V339.543C613.146 339.774 613.238 339.996 613.402 340.16C613.566 340.324 613.788 340.416 614.019 340.416H644.934C645.165 340.415 645.387 340.323 645.55 340.159C645.714 339.996 645.807 339.774 645.808 339.543V335.072H613.146Z" fill="#3F3D56"/>
                       <path id="Vector_37" d="M690.045 308.646C691.138 308.646 692.024 307.76 692.024 306.666C692.024 305.573 691.138 304.687 690.045 304.687C688.952 304.687 688.065 305.573 688.065 306.666C688.065 307.76 688.952 308.646 690.045 308.646Z" fill="#2F2E41"/>
                       <path id="Vector_38" d="M583.158 303.795C583.067 303.795 582.977 303.813 582.892 303.848C582.808 303.883 582.732 303.934 582.668 303.998C582.603 304.063 582.552 304.139 582.518 304.223C582.483 304.307 582.465 304.397 582.465 304.488V308.052C582.465 308.236 582.539 308.411 582.668 308.541C582.798 308.67 582.974 308.743 583.158 308.743C583.341 308.743 583.517 308.67 583.647 308.541C583.777 308.411 583.85 308.236 583.85 308.052V304.488C583.85 304.397 583.832 304.307 583.798 304.223C583.763 304.139 583.712 304.063 583.647 303.998C583.583 303.934 583.507 303.883 583.423 303.848C583.339 303.813 583.249 303.795 583.158 303.795V303.795Z" fill="#2F2E41"/>
                       <path id="Vector_39" d="M586.919 303.795C586.828 303.795 586.738 303.813 586.654 303.848C586.57 303.883 586.494 303.934 586.429 303.998C586.365 304.063 586.314 304.139 586.279 304.223C586.244 304.307 586.226 304.397 586.226 304.488V308.052C586.226 308.236 586.299 308.412 586.429 308.542C586.559 308.672 586.735 308.745 586.919 308.745C587.103 308.745 587.279 308.672 587.409 308.542C587.539 308.412 587.612 308.236 587.612 308.052V304.488C587.612 304.397 587.594 304.307 587.559 304.223C587.524 304.139 587.473 304.063 587.409 303.998C587.345 303.934 587.268 303.883 587.184 303.848C587.1 303.813 587.01 303.795 586.919 303.795V303.795Z" fill="#2F2E41"/>
                       <path id="Vector_40" d="M590.681 303.795C590.59 303.795 590.5 303.813 590.416 303.848C590.332 303.883 590.255 303.934 590.191 303.998C590.127 304.063 590.076 304.139 590.041 304.223C590.006 304.307 589.988 304.397 589.988 304.488V308.052C589.989 308.236 590.062 308.411 590.192 308.541C590.321 308.67 590.497 308.743 590.681 308.743C590.864 308.743 591.04 308.67 591.17 308.541C591.3 308.411 591.373 308.236 591.373 308.052V304.488C591.373 304.397 591.356 304.307 591.321 304.223C591.286 304.139 591.235 304.063 591.171 303.998C591.106 303.934 591.03 303.883 590.946 303.848C590.862 303.813 590.772 303.795 590.681 303.795Z" fill="#2F2E41"/>
                       <path id="Vector_41" d="M594.442 303.795C594.351 303.795 594.261 303.813 594.177 303.848C594.093 303.883 594.017 303.934 593.952 303.998C593.888 304.063 593.837 304.139 593.802 304.223C593.767 304.307 593.75 304.397 593.75 304.488V308.052C593.75 308.236 593.823 308.411 593.953 308.541C594.083 308.67 594.259 308.743 594.442 308.743C594.626 308.743 594.802 308.67 594.931 308.541C595.061 308.411 595.134 308.236 595.135 308.052V304.488C595.135 304.397 595.117 304.307 595.082 304.223C595.047 304.139 594.996 304.063 594.932 303.998C594.868 303.934 594.791 303.883 594.707 303.848C594.623 303.813 594.533 303.795 594.442 303.795V303.795Z" fill="#2F2E41"/>
                       <path id="Vector_42" d="M668.583 339.035H592.465L594.076 331.383H666.972L668.583 339.035Z" fill="#2F2E41"/>
                   </g>
                   <g id="person_2">
                       <path id="ab6171fa-7d69-4734-b81c-8dff60f9761b" d="M630.688 218.094C646.046 218.094 658.496 205.644 658.496 190.285C658.496 174.927 646.046 162.477 630.688 162.477C615.33 162.477 602.88 174.927 602.88 190.285C602.88 205.644 615.33 218.094 630.688 218.094Z" fill="#FFB8B8"/>
                       <path id="bf427902-b9bf-4946-b5d7-5c1c7e04535e" d="M599.667 163.534C599.877 160.34 601.989 157.073 608.698 155.365C608.698 155.365 620.213 140.64 635.861 148.362C635.861 148.362 643.447 147.057 649.072 154.928C649.072 154.928 654.648 151.638 657.001 157.468C657.001 157.468 663.542 169.36 661.134 181.49C658.726 193.62 657.494 195.134 657.494 195.134C657.494 195.134 660.563 170.044 642.884 171.783C625.205 173.523 610.972 167.306 606.969 180.918C603.644 192.221 604.063 198.091 604.063 198.091C604.063 198.091 592.946 186.816 596.726 177.937C598.659 173.396 599.343 168.459 599.667 163.534Z" fill="#2F2E41"/>
                       <path id="left-arm" d="M597.444 337.021C603.227 337.021 607.915 332.333 607.915 326.55C607.915 320.767 603.227 316.079 597.444 316.079C591.661 316.079 586.972 320.767 586.972 326.55C586.972 332.333 591.661 337.021 597.444 337.021Z" fill="#FFB8B8"/>
                       <path id="right-arm" d="M670.596 339.035C676.38 339.035 681.068 334.347 681.068 328.564C681.068 322.781 676.38 318.093 670.596 318.093C664.813 318.093 660.125 322.781 660.125 328.564C660.125 334.347 664.813 339.035 670.596 339.035Z" fill="#FFB8B8"/>
                   </g>
                   <g id="mountains">
                       <path id="Vector_43" d="M778.678 202.173C777.644 200.461 776.417 198.634 774.497 198.075C772.274 197.428 769.989 198.714 768.052 199.982C762.257 203.776 756.676 207.888 751.336 212.298L751.342 212.365C755.5 212.078 758.95 223.133 763.108 222.847C766.112 222.64 769.926 211.062 772.581 209.64C773.589 209.101 774.571 208.381 775.712 208.331C777.13 208.268 778.367 209.259 779.339 210.294C785.087 216.415 786.751 225.776 793.2 231.154C789.133 221.125 784.278 211.434 778.678 202.173V202.173Z" fill="#3F3D56"/>
                       <path id="Vector_44" d="M789.743 217.041C792.776 222.518 795.992 227.887 799.393 233.147V233.151C799.372 233.336 799.344 233.521 799.316 233.706C798.905 233.078 798.499 232.447 798.096 231.814C795.043 227.025 792.142 222.145 789.393 217.174C788.088 214.81 786.815 212.427 785.575 210.025C784.616 208.165 783.678 206.293 782.759 204.411C782.498 203.872 782.244 203.332 781.99 202.788C781.394 201.515 780.806 200.235 780.178 198.978C779.461 197.544 778.587 196.046 777.27 195.084C776.671 194.627 775.954 194.351 775.204 194.286C774.147 194.293 773.112 194.589 772.212 195.144C765.399 198.725 759.04 203.11 753.271 208.205C747.495 213.28 742.332 219.012 737.886 225.285C737.749 225.478 737.427 225.293 737.564 225.096C738.108 224.331 738.655 223.578 739.219 222.828C746.207 213.536 754.775 205.545 764.531 199.22C766.171 198.157 767.842 197.143 769.546 196.179C770.403 195.692 771.265 195.213 772.143 194.761C772.984 194.269 773.926 193.977 774.898 193.908C778.241 193.855 779.972 197.641 781.161 200.186C781.531 200.988 781.908 201.788 782.292 202.587C783.738 205.607 785.24 208.604 786.799 211.576C787.757 213.408 788.739 215.23 789.743 217.041Z" fill="#3F3D56"/>
                       <path id="Vector_45" d="M752.942 206.335C752.748 206.119 752.555 205.902 752.359 205.686C750.818 203.981 749.165 202.297 747.048 201.32C746.039 200.839 744.938 200.584 743.82 200.571C742.667 200.603 741.53 200.851 740.468 201.302C739.964 201.504 739.47 201.733 738.984 201.977C738.429 202.256 737.883 202.554 737.34 202.855C736.321 203.419 735.315 204.007 734.323 204.621C732.347 205.842 730.434 207.156 728.582 208.563C727.623 209.292 726.682 210.046 725.762 210.824C724.906 211.546 724.068 212.288 723.247 213.049C723.071 213.211 722.809 212.949 722.984 212.787C723.201 212.585 723.42 212.385 723.639 212.187C724.257 211.628 724.885 211.079 725.524 210.542C726.687 209.56 727.881 208.615 729.106 207.709C731.009 206.299 732.978 204.985 735.013 203.766C736.029 203.158 737.06 202.575 738.105 202.017C738.42 201.849 738.739 201.685 739.062 201.529C739.795 201.159 740.556 200.849 741.338 200.602C742.446 200.244 743.618 200.129 744.775 200.265C745.892 200.427 746.968 200.799 747.946 201.362C750.024 202.523 751.636 204.319 753.204 206.072C753.363 206.25 753.102 206.513 752.942 206.335Z" fill="#3F3D56"/>
                       <path id="Vector_46" d="M737.737 214.536C737.209 214.562 736.687 214.412 736.252 214.11C735.102 213.239 735.133 211.147 736.344 207.713C736.674 206.783 737.191 205.93 737.864 205.208C738.536 204.485 739.349 203.908 740.253 203.512C741.1 203.133 742.017 202.935 742.945 202.929C743.873 202.924 744.791 203.111 745.643 203.48C745.791 203.544 745.938 203.614 746.083 203.688C747.068 204.24 747.992 204.895 748.838 205.643C749.558 206.267 750.325 206.834 751.134 207.339L751.403 207.497L751.148 207.677C744.624 212.27 740.201 214.536 737.737 214.536Z" fill="#3F3D56"/>
                   </g>
                   <path id="mountain_wave" d="M788.768 246.046C791.128 247.71 793.396 249.405 795.989 250.686C795.941 250.883 795.889 251.085 795.84 251.282C795.522 251.133 795.212 250.976 794.902 250.815C792.272 249.437 790.009 247.565 787.54 245.938C786.316 245.092 784.979 244.422 783.569 243.948C782.099 243.506 780.563 243.326 779.03 243.416C772.639 243.731 767.161 247.545 761.221 249.466C758.313 250.408 755.22 250.911 752.187 250.319C749.155 249.727 746.461 248.116 743.782 246.659C741.14 245.217 738.261 243.767 735.176 243.767C732.663 243.771 730.021 244.778 728.676 247.001C728.634 247.067 728.568 247.115 728.491 247.133C728.415 247.152 728.335 247.14 728.267 247.1C728.2 247.06 728.15 246.996 728.129 246.92C728.109 246.844 728.118 246.764 728.156 246.695C728.457 246.188 728.827 245.726 729.255 245.321C731.011 243.658 733.593 243.002 735.965 243.195C739.034 243.445 741.833 244.895 744.487 246.352C747.302 247.899 750.186 249.562 753.44 249.901C756.654 250.239 759.836 249.397 762.816 248.266C768.587 246.071 774.282 242.414 780.701 242.82C783.73 243.014 786.324 244.327 788.768 246.046Z" fill="#3F3D56"/>
                   <path id="moon" d="M762.824 187.202C760.678 187.202 758.6 186.457 756.942 185.095C755.285 183.733 754.152 181.838 753.736 179.733C751.942 180.096 750.296 180.983 749.006 182.28C747.715 183.578 746.838 185.229 746.485 187.025C746.132 188.821 746.319 190.681 747.022 192.37C747.725 194.06 748.912 195.504 750.435 196.519C751.958 197.534 753.747 198.076 755.577 198.075C757.407 198.075 759.196 197.532 760.718 196.516C762.24 195.499 763.427 194.055 764.129 192.365C764.831 190.675 765.017 188.814 764.662 187.019C764.057 187.141 763.441 187.202 762.824 187.202V187.202Z" fill="#3F3D56"/>
                   <path id="left_circle_1" opacity="0.74" d="M514.866 202.103C522.5 202.103 528.689 195.915 528.689 188.28C528.689 180.646 522.5 174.457 514.866 174.457C507.232 174.457 501.043 180.646 501.043 188.28C501.043 195.915 507.232 202.103 514.866 202.103Z" fill="#3F3D56"/>
                   <g id="left_circle_3">
                       <path id="Vector_47" opacity="0.74" d="M528.689 202.103C536.323 202.103 542.512 195.915 542.512 188.28C542.512 180.646 536.323 174.457 528.689 174.457C521.055 174.457 514.866 180.646 514.866 188.28C514.866 195.915 521.055 202.103 528.689 202.103Z" fill="#056D65"/>
                       <path id="Vector_48" d="M558.04 173.302L522.534 184.457L522.776 185.226L558.282 174.071L558.04 173.302Z" fill="#3F3D56"/>
                       <path id="Vector_49" d="M558.266 175.169C559.156 175.169 559.877 174.447 559.877 173.558C559.877 172.668 559.156 171.947 558.266 171.947C557.377 171.947 556.655 172.668 556.655 173.558C556.655 174.447 557.377 175.169 558.266 175.169Z" fill="#3F3D56"/>
                   </g>
                   <path id="left_circle_2" opacity="0.74" d="M521.894 191.087C529.529 191.087 535.717 184.898 535.717 177.264C535.717 169.629 529.529 163.44 521.894 163.44C514.26 163.44 508.071 169.629 508.071 177.264C508.071 184.898 514.26 191.087 521.894 191.087Z" fill="#FF6584"/>
                   <g id="left_squares">
                       <path id="Vector_50" d="M536.154 129.57V130.388C535.883 130.334 535.608 130.307 535.332 130.307H531.12V139.353C531.119 140.692 530.586 141.976 529.639 142.923C528.692 143.87 527.408 144.402 526.069 144.403H519.843V145.796C519.843 146.355 519.954 146.908 520.169 147.423H519.307C519.127 146.9 519.036 146.35 519.037 145.796V144.403H515.614V143.597H519.037V134.552C519.038 133.213 519.57 131.929 520.517 130.982C521.464 130.035 522.748 129.502 524.088 129.502H530.314V128.092H531.12V129.502H535.332C535.607 129.502 535.882 129.525 536.154 129.57V129.57Z" fill="#056D65"/>
                       <path id="Vector_51" d="M526.069 123.058H514.825C513.485 123.058 512.201 123.591 511.254 124.538C510.307 125.485 509.775 126.769 509.774 128.108V139.353C509.775 140.692 510.307 141.976 511.254 142.923C512.201 143.87 513.485 144.402 514.825 144.403H526.069C527.408 144.402 528.692 143.87 529.639 142.923C530.586 141.976 531.119 140.692 531.119 139.353V128.092C531.115 126.756 530.58 125.475 529.634 124.532C528.687 123.588 527.406 123.058 526.069 123.058V123.058ZM530.314 139.353C530.312 140.478 529.865 141.557 529.069 142.352C528.273 143.148 527.194 143.596 526.069 143.597H514.825C513.699 143.596 512.62 143.148 511.825 142.352C511.029 141.557 510.581 140.478 510.58 139.353V128.108C510.581 126.983 511.029 125.904 511.825 125.108C512.62 124.312 513.699 123.865 514.825 123.863H526.069C527.192 123.864 528.268 124.31 529.064 125.102C529.859 125.895 530.308 126.969 530.314 128.092V139.353Z" fill="#3F3D56"/>
                       <path id="Vector_52" d="M519.037 143.597V144.403H519.843V143.597H519.037ZM519.037 143.597V144.403H519.843V143.597H519.037ZM536.154 129.57C535.882 129.525 535.607 129.502 535.332 129.502H524.087C522.748 129.502 521.464 130.035 520.517 130.982C519.57 131.929 519.038 133.213 519.037 134.552V145.796C519.036 146.35 519.127 146.9 519.307 147.423C519.647 148.422 520.291 149.288 521.148 149.902C522.005 150.516 523.033 150.846 524.087 150.847H535.332C536.671 150.846 537.955 150.314 538.902 149.367C539.849 148.42 540.382 147.136 540.382 145.796V134.552C540.382 133.355 539.957 132.197 539.182 131.285C538.408 130.372 537.334 129.765 536.154 129.57V129.57ZM539.577 145.796C539.575 146.922 539.128 148.001 538.332 148.796C537.536 149.592 536.457 150.04 535.332 150.041H524.087C522.962 150.04 521.883 149.592 521.088 148.796C520.292 148.001 519.844 146.922 519.843 145.796V134.552C519.844 133.427 520.292 132.348 521.088 131.552C521.883 130.756 522.962 130.309 524.087 130.307H535.332C536.458 130.307 537.537 130.755 538.333 131.551C539.129 132.347 539.576 133.426 539.577 134.552V145.796ZM519.037 143.597V144.403H519.843V143.597H519.037Z" fill="#3F3D56"/>
                   </g>
                   <g id="lines">
                       <path id="Vector_53" d="M506.532 276.763H470.326C470.757 277.774 471.196 278.777 471.651 279.776H506.532C508.498 279.804 508.477 276.735 506.532 276.763Z" fill="#E4E4E4"/>
                       <path id="Vector_54" d="M506.532 283.666H473.468C473.951 284.677 474.448 285.681 474.958 286.679H506.532C508.498 286.707 508.478 283.642 506.532 283.666Z" fill="#E4E4E4"/>
                       <path id="Vector_55" d="M506.532 290.573H476.991C477.531 291.584 478.087 292.587 478.651 293.586H506.532C508.498 293.614 508.478 290.545 506.532 290.573Z" fill="#E4E4E4"/>
                   </g>
                   <g id="right_square_1">
                       <path id="Vector_56" opacity="0.21" d="M724.355 104.924H724.125C724.84 104.925 725.544 105.091 726.183 105.411H725.139C724.013 105.412 722.934 105.86 722.139 106.656C721.343 107.451 720.895 108.53 720.894 109.656V120.9C720.895 122.026 721.343 123.105 722.139 123.901C722.934 124.696 724.013 125.144 725.139 125.145H725.805C725.27 125.355 724.7 125.463 724.125 125.464H724.355C725.587 125.463 726.768 124.972 727.64 124.101C728.511 123.23 729.001 122.049 729.002 120.817V109.572C729.001 108.34 728.511 107.158 727.64 106.287C726.768 105.416 725.587 104.926 724.355 104.924V104.924Z" fill="black"/>
                       <path id="ri" d="M725.183 125.18C724.058 125.179 722.979 124.731 722.183 123.936C721.388 123.14 720.94 122.061 720.939 120.935V109.691C720.94 108.565 721.388 107.486 722.183 106.691C722.979 105.895 724.058 105.447 725.183 105.446H726.228C725.589 105.127 724.884 104.96 724.17 104.959H712.925C711.693 104.961 710.512 105.451 709.641 106.322C708.769 107.193 708.279 108.375 708.278 109.607V120.852C708.279 122.084 708.769 123.265 709.641 124.136C710.512 125.007 711.693 125.498 712.925 125.499H724.17C724.745 125.498 725.314 125.39 725.849 125.18L725.183 125.18Z" fill="#056D65"/>
                       <path id="Vector_57" d="M724.355 104.924H724.125C724.84 104.925 725.544 105.091 726.183 105.411H725.139C724.013 105.412 722.934 105.86 722.139 106.656C721.343 107.451 720.895 108.53 720.894 109.656V120.9C720.895 122.026 721.343 123.105 722.139 123.901C722.934 124.696 724.013 125.144 725.139 125.145H725.805C725.27 125.355 724.7 125.463 724.125 125.464H724.355C725.587 125.463 726.768 124.972 727.64 124.101C728.511 123.23 729.001 122.049 729.002 120.817V109.572C729.001 108.34 728.511 107.158 727.64 106.287C726.768 105.416 725.587 104.926 724.355 104.924V104.924Z" fill="#056D65"/>
                   </g>
                   <g id="right_square_2">
                       <path id="Vector_58" d="M754.157 104.924H753.928C754.642 104.925 755.347 105.091 755.986 105.411H754.941C753.816 105.412 752.737 105.86 751.941 106.656C751.145 107.451 750.698 108.53 750.697 109.656V120.9C750.698 122.026 751.145 123.105 751.941 123.901C752.737 124.696 753.816 125.144 754.941 125.145H755.607C755.072 125.355 754.503 125.463 753.928 125.464H754.157C755.39 125.463 756.571 124.972 757.442 124.101C758.313 123.23 758.803 122.049 758.805 120.817V109.572C758.803 108.34 758.313 107.158 757.442 106.287C756.571 105.416 755.39 104.926 754.157 104.924V104.924Z" fill="#3F3D56"/>
                       <path id="Vector_59" opacity="0.21" d="M754.157 104.924H753.928C754.642 104.925 755.347 105.091 755.986 105.411H754.941C753.816 105.412 752.737 105.86 751.941 106.656C751.145 107.451 750.698 108.53 750.697 109.656V120.9C750.698 122.026 751.145 123.105 751.941 123.901C752.737 124.696 753.816 125.144 754.941 125.145H755.607C755.072 125.355 754.503 125.463 753.928 125.464H754.157C755.39 125.463 756.571 124.972 757.442 124.101C758.313 123.23 758.803 122.049 758.805 120.817V109.572C758.803 108.34 758.313 107.158 757.442 106.287C756.571 105.416 755.39 104.926 754.157 104.924V104.924Z" fill="black"/>
                       <path id="Vector_60" d="M754.986 125.18C753.861 125.179 752.782 124.731 751.986 123.936C751.19 123.14 750.743 122.061 750.741 120.935V109.691C750.743 108.565 751.19 107.486 751.986 106.691C752.782 105.895 753.861 105.447 754.986 105.446H756.031C755.392 105.127 754.687 104.96 753.973 104.959H742.728C741.496 104.961 740.315 105.451 739.443 106.322C738.572 107.193 738.082 108.375 738.081 109.607V120.852C738.082 122.084 738.572 123.265 739.443 124.136C740.315 125.007 741.496 125.498 742.728 125.499H753.973C754.548 125.498 755.117 125.39 755.652 125.18L754.986 125.18Z" fill="#3F3D56"/>
                   </g>
                   <path id="right_square_3" d="M768.265 107.758C772.392 113.435 776.167 119.36 779.57 125.498H772.53C771.298 125.496 770.117 125.006 769.246 124.135C768.374 123.264 767.884 122.083 767.882 120.851V109.606C767.882 108.97 768.012 108.341 768.265 107.758V107.758Z" fill="#FF6584"/>
                   <path id="mouse" d="M754.149 131.398L750.21 125.498L747.331 121.185C747.163 120.933 746.927 120.734 746.65 120.611C746.373 120.487 746.068 120.445 745.768 120.488C745.468 120.532 745.187 120.659 744.957 120.856C744.726 121.053 744.557 121.311 744.467 121.6L743.255 125.498L740.814 133.332C740.718 133.644 740.719 133.978 740.817 134.289C740.916 134.601 741.107 134.875 741.365 135.075C741.623 135.275 741.936 135.391 742.262 135.409C742.589 135.426 742.912 135.344 743.19 135.172L744.056 134.636C744.231 134.529 744.429 134.468 744.634 134.459C744.838 134.45 745.042 134.493 745.225 134.585C745.408 134.676 745.565 134.813 745.68 134.982C745.796 135.151 745.866 135.347 745.885 135.551L746.678 144.21C746.715 144.607 746.899 144.977 747.194 145.246C747.489 145.516 747.874 145.666 748.273 145.668C748.321 145.668 748.37 145.663 748.422 145.659L749.638 145.551C749.848 145.532 750.052 145.471 750.239 145.373C750.425 145.275 750.59 145.142 750.725 144.98C750.86 144.818 750.961 144.631 751.023 144.429C751.086 144.228 751.108 144.017 751.088 143.807L750.271 134.866C750.251 134.656 750.287 134.445 750.376 134.253C750.464 134.061 750.601 133.897 750.774 133.775C750.946 133.653 751.147 133.579 751.357 133.56C751.567 133.541 751.779 133.577 751.97 133.666L752.147 133.746C752.465 133.893 752.821 133.931 753.162 133.856C753.503 133.781 753.811 133.596 754.037 133.33C754.264 133.065 754.397 132.732 754.418 132.383C754.438 132.034 754.343 131.689 754.149 131.398V131.398Z" fill="#CCCCCC"/>
               </g>
               <defs>
                   <clipPath id="clip0_27_69">
                       <rect width="1261" height="404" fill="white"/>
                   </clipPath>
               </defs>
           </svg>


       </div>
    </>)
}