import "./HomeButton.scss"

import {useNavigate} from "react-router-dom";

export default function HomeButton(props){
    const navigate = useNavigate()

    const clickHandler = (pageId)=>{
        document.getElementById(pageId).style.opacity = '0'
        setTimeout(()=>{
            navigate('/')
        },250)

    }

    return(
        <button className="home-btn" onClick={()=>{clickHandler(props.page)}}>
            <svg width="75" height="75" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="home-btn">
                    <g id="home-btn-house">
                        <path id="Vector" d="M59.2498 61.1241H16.0357C15.7678 61.1238 15.5109 61.0172 15.3215 60.8278C15.1321 60.6383 15.0255 60.3815 15.0252 60.1136V30.6051C15.0255 30.3372 15.1321 30.0804 15.3215 29.891C15.5109 29.7015 15.7678 29.595 16.0357 29.5947H59.2498C59.5177 29.595 59.7745 29.7015 59.964 29.891C60.1534 30.0804 60.26 30.3372 60.2603 30.6051V60.1136C60.26 60.3815 60.1534 60.6383 59.964 60.8278C59.7745 61.0172 59.5177 61.1238 59.2498 61.1241Z" fill="white"/>
                        <path id="Vector_2" d="M59.2498 61.1241H16.0357C15.7678 61.1238 15.5109 61.0172 15.3215 60.8278C15.1321 60.6383 15.0255 60.3815 15.0252 60.1136V30.6051C15.0255 30.3372 15.1321 30.0804 15.3215 29.891C15.5109 29.7015 15.7678 29.595 16.0357 29.5947H59.2498C59.5177 29.595 59.7745 29.7015 59.964 29.891C60.1534 30.0804 60.26 30.3372 60.2603 30.6051V60.1136C60.26 60.3815 60.1534 60.6383 59.964 60.8278C59.7745 61.0172 59.5177 61.1238 59.2498 61.1241ZM16.0357 29.8192C15.8273 29.8195 15.6275 29.9023 15.4802 30.0497C15.3329 30.197 15.25 30.3968 15.2498 30.6051V60.1136C15.25 60.322 15.3329 60.5217 15.4802 60.6691C15.6275 60.8164 15.8273 60.8993 16.0357 60.8995H59.2498C59.4582 60.8993 59.6579 60.8164 59.8053 60.6691C59.9526 60.5217 60.0355 60.322 60.0357 60.1136V30.6051C60.0355 30.3968 59.9526 30.197 59.8053 30.0497C59.6579 29.9023 59.4582 29.8195 59.2498 29.8192H16.0357Z" fill="#3F3D56"/>
                        <path id="Vector_3" d="M42.6451 60.9465H32.6404V44.3837C32.6408 43.9908 32.7971 43.6142 33.0748 43.3364C33.3526 43.0587 33.7292 42.9024 34.1221 42.902H41.1634C41.5562 42.9024 41.9328 43.0587 42.2106 43.3364C42.4884 43.6142 42.6446 43.9908 42.6451 44.3837V60.9465Z" fill="#E6E6E6"/>
                        <path id="Vector_4" d="M28.4148 47.7131H19.9637C19.6266 47.7128 19.3034 47.5787 19.0651 47.3403C18.8267 47.1019 18.6926 46.7788 18.6923 46.4417V39.4745C18.6926 39.1374 18.8267 38.8142 19.0651 38.5759C19.3035 38.3375 19.6266 38.2034 19.9637 38.2031H28.4148C28.7518 38.2034 29.075 38.3375 29.3134 38.5759C29.5517 38.8142 29.6858 39.1374 29.6862 39.4745V46.4417C29.6858 46.7788 29.5517 47.1019 29.3134 47.3403C29.075 47.5787 28.7518 47.7128 28.4148 47.7131V47.7131Z" fill="#E6E6E6"/>
                        <path id="Vector_5" d="M55.5173 47.7131H47.0663C46.7292 47.7128 46.406 47.5787 46.1676 47.3403C45.9292 47.1019 45.7952 46.7788 45.7948 46.4417V39.4745C45.7952 39.1374 45.9293 38.8142 46.1676 38.5759C46.406 38.3375 46.7292 38.2034 47.0663 38.2031H55.5173C55.8544 38.2034 56.1776 38.3375 56.4159 38.5759C56.6543 38.8142 56.7884 39.1374 56.7887 39.4745V46.4417C56.7884 46.7788 56.6543 47.1019 56.4159 47.3403C56.1776 47.5787 55.8544 47.7128 55.5173 47.7131V47.7131Z" fill="#E6E6E6"/>
                    </g>
                    <g id="home-btn-roof">
                        <path id="Vector_6" d="M56.893 23.3046H51.1796V13.7563C51.1799 13.4884 51.2864 13.2315 51.4759 13.0421C51.6653 12.8527 51.9221 12.7461 52.19 12.7458H55.8826C56.1505 12.7461 56.4073 12.8527 56.5967 13.0421C56.7862 13.2315 56.8927 13.4884 56.893 13.7563V23.3046Z" fill="white"/>
                        <path id="Vector_7" d="M56.893 23.3046H51.1796V13.7563C51.1799 13.4884 51.2864 13.2315 51.4759 13.0421C51.6653 12.8527 51.9221 12.7461 52.19 12.7458H55.8826C56.1505 12.7461 56.4073 12.8527 56.5967 13.0421C56.7862 13.2315 56.8927 13.4884 56.893 13.7563V23.3046ZM51.4041 23.0801H56.6685V13.7563C56.6682 13.5479 56.5854 13.3481 56.438 13.2008C56.2907 13.0535 56.0909 12.9706 55.8826 12.9703H52.19C51.9817 12.9706 51.7819 13.0535 51.6346 13.2008C51.4872 13.3481 51.4044 13.5479 51.4041 13.7563V23.0801Z" fill="#3F3D56"/>
                        <path id="Vector_8" d="M11.7988 30.2016V25.6615C11.7988 25.3127 11.891 24.9702 12.066 24.6685C12.241 24.3669 12.4927 24.1168 12.7955 23.9438L38.0211 9.52609C38.3342 9.34713 38.6903 9.25715 39.0508 9.2659C39.4114 9.27465 39.7627 9.38179 40.0668 9.57574L62.5721 23.9301C62.8524 24.1089 63.0832 24.3555 63.243 24.647C63.4029 24.9386 63.4866 25.2657 63.4866 25.5982V30.2016C63.4866 30.7263 63.2782 31.2296 62.9072 31.6006C62.5361 31.9716 62.0329 32.1801 61.5082 32.1801H13.7772C13.2525 32.1801 12.7493 31.9716 12.3782 31.6006C12.0072 31.2296 11.7988 30.7263 11.7988 30.2016V30.2016Z" fill="#056D65"/>
                        <path id="Vector_9" opacity="0.2" d="M61.5082 30.1587H13.7772C13.2525 30.1587 12.7493 29.9503 12.3782 29.5792C12.0072 29.2082 11.7988 28.705 11.7988 28.1802V29.9766C11.7988 30.5014 12.0072 31.0046 12.3782 31.3756C12.7493 31.7467 13.2525 31.9551 13.7772 31.9551H61.5082C62.0329 31.9551 62.5361 31.7467 62.9072 31.3756C63.2782 31.0046 63.4866 30.5014 63.4866 29.9766V28.1802C63.4866 28.705 63.2782 29.2082 62.9072 29.5792C62.5361 29.9503 62.0329 30.1587 61.5082 30.1587V30.1587Z" fill="black"/>
                    </g>
                    <circle id="Ellipse 1" cx="37.5" cy="37.5" r="36" stroke="#056D65" strokeWidth="3"/>
                </g>
            </svg>
        </button>
    )
}