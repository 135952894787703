import "./SkillsSVG.scss"

export default function SkillsSVG(){
    return(<>
        <div className="svg-anim" id="skills-svg">
            <svg width="362" height="309" viewBox="0 0 362 309" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="undraw_percentages_re_a1ao (2) 1" clipPath="url(#clip0_101_35)">
                    <g id="human">
                        <path id="Vector" d="M319.454 307.796L319.462 307.435C319.625 301.394 321.101 295.46 323.787 290.046C326.474 284.633 330.306 279.87 335.018 276.088C337.677 273.988 340.591 272.231 343.689 270.859L343.985 270.728L344.035 271.047C344.635 274.862 345.585 278.756 346.028 280.484L348.995 268.898L349.325 268.803C350.837 268.358 352.447 268.378 353.948 268.857C355.45 269.337 356.773 270.256 357.747 271.495C358.764 272.734 359.387 274.25 359.535 275.847C359.684 277.444 359.351 279.048 358.58 280.454C357.888 281.744 357.208 283.089 356.55 284.39C354.291 288.861 351.955 293.484 348.55 297.076C345.946 299.769 342.774 301.847 339.265 303.157C335.756 304.466 332 304.975 328.269 304.646L319.454 307.796Z" fill="#F2F2F2"/>
                        <path id="Vector_2" d="M282.456 302.437L277.292 302.437L274.835 282.507L282.457 282.507L282.456 302.437Z" fill="#FFB6B6"/>
                        <path id="Vector_3" d="M282.638 307.828L266.758 307.827V307.626C266.758 305.986 267.41 304.413 268.569 303.253C269.728 302.093 271.3 301.442 272.939 301.442H272.939L275.84 299.24L281.252 301.442L282.638 301.442L282.638 307.828Z" fill="#2F2E41"/>
                        <path id="Vector_4" d="M335.907 287.881L331.828 291.048L317.675 276.811L323.696 272.135L335.907 287.881Z" fill="#FFB6B6"/>
                        <path id="Vector_5" d="M339.354 292.027L326.809 301.769L326.686 301.61C325.681 300.314 325.232 298.672 325.437 297.045C325.642 295.417 326.484 293.938 327.779 292.932L327.779 292.932L328.722 289.413L334.346 287.833L335.441 286.982L339.354 292.027Z" fill="#2F2E41"/>
                        <path id="Vector_6" d="M313.571 191.452C313.571 191.452 313.219 196.848 312.774 204.561C312.701 205.826 313.131 207.201 313.055 208.577C313.004 209.49 312.447 210.378 312.395 211.33C312.341 212.335 313.109 213.224 313.055 214.263C313.001 215.278 312.124 216.443 312.071 217.482C311.502 228.665 309.438 242.052 311.505 245.795C312.523 247.639 328.036 273.191 328.036 273.191C328.036 273.191 320.804 284.047 318.737 280.428C316.008 275.649 294.974 253.29 294.974 249.02C294.974 244.756 290.108 212.795 290.108 212.795L286.715 231.709L285.159 235.457L285.648 237.657L284.642 240.626L284.588 243.564L283.609 249.02C283.609 249.02 286.553 291.532 284.895 292.715C283.232 293.904 275.178 296.039 274.228 293.904C273.282 291.769 269.661 249.537 269.661 249.537C269.661 249.537 268.669 230.184 267.595 212.195C267.533 211.151 266.799 209.89 266.742 208.861C266.675 207.667 267.14 206.117 267.078 204.959C267.011 203.63 266.422 202.695 266.365 201.423C265.926 191.596 265.859 183.853 266.644 182.674C269.015 179.118 271.149 176.275 271.149 176.275C271.149 176.275 305.037 167.973 313.571 178.642L313.571 191.452Z" fill="#2F2E41"/>
                        <path id="Vector_7" d="M283.092 89.6865L291.263 89.2293L297.421 92.5928L314.545 189.626C314.545 189.626 297.04 197.722 286.192 194.62C275.343 191.519 263.919 191.694 263.919 191.694L272.244 98.9909L278.959 92.788L283.092 89.6865Z" fill="#056D65"/>
                        <path id="Vector_8" d="M296.724 91.7437L309.372 96.912C311.314 97.0067 313.167 97.7565 314.628 99.0394C316.09 100.322 317.075 102.063 317.422 103.977L316.583 128.971L312.021 140.861L315.274 167.948L313.571 171.359L315.043 174.841C312.883 178.351 313.322 182.949 314.604 187.9H313.055L309.955 187.384C309.955 187.384 303.131 189.754 301.733 181.24C298.073 158.953 296.007 168.775 296.007 168.775L294.206 99.6255L296.724 91.7437Z" fill="#3F3D56"/>
                        <path id="Vector_9" d="M279.476 91.7542L267.661 104.149C265.719 104.243 263.866 104.993 262.405 106.276C260.943 107.559 259.958 109.299 259.611 111.214L264.495 149.649L262.203 164.396L263.462 167.741L262.55 171.521L263.978 174.978L262.945 179.63L264.495 186.35L272.244 184.282L272.185 178.771L274.251 103.818L279.476 91.7542Z" fill="#3F3D56"/>
                        <path id="Vector_10" d="M240.074 155.468L223.98 167.153C223.363 167.185 222.766 167.379 222.248 167.716C221.73 168.052 221.31 168.519 221.03 169.07C220.75 169.62 220.62 170.235 220.652 170.852C220.685 171.469 220.88 172.066 221.216 172.584C221.553 173.101 222.02 173.521 222.571 173.801C223.121 174.081 223.736 174.21 224.352 174.177C224.969 174.143 225.566 173.948 226.083 173.61C226.6 173.273 227.019 172.805 227.297 172.253L249.183 160.563L240.074 155.468Z" fill="#FFB6B6"/>
                        <path id="Vector_11" d="M262.429 106.228C259.37 107.639 251.813 143.782 249.596 144.549C247.378 145.315 248.225 145.2 247.914 145.84C247.602 146.479 247.527 149.344 245.398 149.68C243.269 150.016 232.021 160.278 232.021 160.278L234.872 169.789C234.872 169.789 256.518 157.414 262.486 150.562C268.455 143.71 271.668 108.47 271.668 108.47L272.279 104.954C272.279 104.954 265.488 104.816 262.429 106.228Z" fill="#3F3D56"/>
                        <path id="Vector_12" d="M310.969 150.695L296.626 164.477C296.02 164.593 295.454 164.867 294.988 165.271C294.521 165.676 294.168 166.196 293.966 166.779C293.764 167.363 293.719 167.99 293.836 168.596C293.953 169.203 294.227 169.768 294.632 170.235C295.036 170.702 295.557 171.053 296.14 171.255C296.724 171.457 297.35 171.501 297.956 171.383C298.562 171.265 299.127 170.99 299.593 170.585C300.058 170.179 300.409 169.659 300.61 169.074L320.689 154.494L310.969 150.695Z" fill="#FFB6B6"/>
                        <path id="Vector_13" d="M313.055 109.329C310.218 111.147 320.997 137.512 318.905 138.575C316.813 139.638 317.636 139.408 317.416 140.084C317.195 140.76 317.513 143.608 315.45 144.233C313.387 144.858 303.649 156.564 303.649 156.564L307.775 165.595C307.775 165.595 327.523 150.37 332.498 142.765C337.472 135.159 334.235 132.59 334.235 132.59C328.064 119.475 322.292 98.0859 313.571 99.5078C313.571 99.5078 315.891 107.512 313.055 109.329Z" fill="#3F3D56"/>
                        <path id="Vector_14" d="M284.845 87.6399C292.493 87.6399 298.693 81.4361 298.693 73.7832C298.693 66.1304 292.493 59.9265 284.845 59.9265C277.197 59.9265 270.997 66.1304 270.997 73.7832C270.997 81.4361 277.197 87.6399 284.845 87.6399Z" fill="#FFB6B6"/>
                        <path id="Vector_15" d="M298.868 82.7118C298.868 82.7118 295.818 83.5485 294.043 84.0144C294.135 82.6929 294.707 81.4557 294.997 80.1628C295.288 78.87 295.225 77.3495 294.265 76.4366C293.304 75.5238 291.304 75.8794 291.15 77.1929C290.337 75.4548 289.523 73.7135 288.708 71.969C286.382 76.7594 281.627 72.6772 276.442 73.8876C273.797 74.511 270.483 74.2432 269.085 71.9112C269.871 71.2517 270.532 70.4575 271.039 69.5658C270.233 69.8025 269.395 69.9088 268.555 69.8807C268.574 69.0466 268.794 68.2292 269.194 67.4975C269.595 66.7657 270.165 66.1409 270.857 65.6757C271.603 65.1879 269.757 63.4521 270.408 62.841C271.743 61.594 272.639 62.4408 273.427 60.797C273.936 59.7805 274.719 58.9272 275.688 58.3343C276.657 57.7413 277.774 57.4326 278.91 57.4431C281.176 57.4962 285.237 57.4743 286.116 59.5635C288.918 57.7279 292.819 58.2465 295.558 60.1721C297.835 61.7664 300.426 66.5854 300.998 69.3058C301.569 72.0261 300.309 80.3409 298.868 82.7118Z" fill="#2F2E41"/>
                        <path id="Vector_16" d="M361.385 308.841L221.001 309C220.92 309 220.84 308.985 220.765 308.954C220.69 308.923 220.622 308.878 220.565 308.82C220.508 308.763 220.462 308.695 220.431 308.62C220.4 308.546 220.384 308.466 220.384 308.385C220.384 308.304 220.4 308.223 220.431 308.149C220.462 308.074 220.508 308.006 220.565 307.949C220.622 307.892 220.69 307.846 220.765 307.815C220.84 307.785 220.92 307.769 221.001 307.769L361.385 307.61C361.548 307.61 361.705 307.675 361.82 307.79C361.935 307.906 362 308.062 362 308.226C362 308.389 361.935 308.545 361.82 308.661C361.705 308.776 361.548 308.841 361.385 308.841Z" fill="#CACACA"/>
                        <path id="Vector_17" d="M304.83 91.7556C304.508 91.2503 304.002 90.8904 303.419 90.7521C302.837 90.6139 302.223 90.7082 301.708 91.0151C302.468 89.9591 301.896 88.1546 300.705 87.4252C299.357 86.5988 297.653 86.7357 296.081 86.9L283.188 88.2475C281.702 88.4028 280.174 88.5702 278.851 89.2655C277.527 89.9608 276.439 91.3178 276.515 92.8114C275.505 92.4404 274.426 92.294 273.353 92.3822C272.293 92.5493 271.248 93.3244 271.135 94.3919C270.953 96.105 272.987 97.0944 274.644 97.564C277.719 98.436 280.795 99.308 283.87 100.18L282.922 100.383C288.974 100.912 295.067 100.03 300.721 97.8062C302.017 97.2951 303.329 96.6828 304.236 95.6254C305.144 94.568 305.54 92.9549 304.83 91.7556Z" fill="#056D65"/>
                    </g>
                    <g id="bar1">
                        <path id="Vector_18" d="M178.346 62.29H32.5454C32.4084 62.29 32.277 62.2355 32.1801 62.1386C32.0832 62.0416 32.0288 61.9101 32.0288 61.773C32.0288 61.636 32.0832 61.5045 32.1801 61.4075C32.277 61.3106 32.4084 61.2561 32.5454 61.2561H178.346C178.483 61.2561 178.615 61.3106 178.712 61.4075C178.809 61.5045 178.863 61.636 178.863 61.773C178.863 61.9101 178.809 62.0416 178.712 62.1386C178.615 62.2355 178.483 62.29 178.346 62.29Z" fill="#E6E6E6"/>
                        <path id="Vector_19" d="M172.283 52.4686H38.4862C36.7736 52.4686 35.1311 51.7878 33.9201 50.5761C32.7091 49.3643 32.0288 47.7208 32.0288 46.0071C32.0288 44.2934 32.7091 42.65 33.9201 41.4382C35.1311 40.2264 36.7736 39.5457 38.4862 39.5457H172.283C173.996 39.5457 175.639 40.2264 176.85 41.4382C178.061 42.65 178.741 44.2934 178.741 46.0071C178.741 47.7208 178.061 49.3643 176.85 50.5761C175.639 51.7878 173.996 52.4686 172.283 52.4686V52.4686ZM38.4862 40.5795C37.0476 40.5795 35.6679 41.1514 34.6507 42.1692C33.6335 43.1871 33.062 44.5676 33.062 46.0071C33.062 47.4466 33.6335 48.8272 34.6507 49.845C35.6679 50.8629 37.0476 51.4347 38.4862 51.4347H172.283C173.722 51.4347 175.102 50.8629 176.119 49.845C177.136 48.8272 177.708 47.4466 177.708 46.0071C177.708 44.5676 177.136 43.1871 176.119 42.1692C175.102 41.1514 173.722 40.5795 172.283 40.5795H38.4862Z" fill="#3F3D56"/>
                    </g>
                    <g id="bar2">
                        <path id="Vector_20" d="M178.346 110.88H32.5454C32.4084 110.88 32.277 110.826 32.1801 110.729C32.0832 110.632 32.0288 110.5 32.0288 110.363C32.0288 110.226 32.0832 110.095 32.1801 109.998C32.277 109.901 32.4084 109.846 32.5454 109.846H178.346C178.483 109.846 178.615 109.901 178.712 109.998C178.809 110.095 178.863 110.226 178.863 110.363C178.863 110.5 178.809 110.632 178.712 110.729C178.615 110.826 178.483 110.88 178.346 110.88Z" fill="#E6E6E6"/>
                        <path id="Vector_21" d="M172.283 101.059H38.4862C36.7736 101.059 35.1311 100.378 33.9201 99.1661C32.7091 97.9544 32.0288 96.3109 32.0288 94.5972C32.0288 92.8835 32.7091 91.24 33.9201 90.0283C35.1311 88.8165 36.7736 88.1357 38.4862 88.1357H172.283C173.996 88.1357 175.639 88.8165 176.85 90.0283C178.061 91.24 178.741 92.8835 178.741 94.5972C178.741 96.3109 178.061 97.9544 176.85 99.1661C175.639 100.378 173.996 101.059 172.283 101.059ZM38.4862 89.1696C37.0476 89.1696 35.6679 89.7414 34.6507 90.7593C33.6335 91.7772 33.062 93.1577 33.062 94.5972C33.062 96.0367 33.6335 97.4172 34.6507 98.4351C35.6679 99.453 37.0476 100.025 38.4862 100.025H172.283C173.722 100.025 175.102 99.453 176.119 98.4351C177.136 97.4172 177.708 96.0367 177.708 94.5972C177.708 93.1577 177.136 91.7772 176.119 90.7593C175.102 89.7414 173.722 89.1696 172.283 89.1696H38.4862Z" fill="#3F3D56"/>
                    </g>
                    <g id="bar3">
                        <path id="Vector_22" d="M178.346 159.47H32.5454C32.4084 159.47 32.277 159.416 32.1801 159.319C32.0832 159.222 32.0288 159.09 32.0288 158.953C32.0288 158.816 32.0832 158.685 32.1801 158.588C32.277 158.491 32.4084 158.436 32.5454 158.436H178.346C178.483 158.436 178.615 158.491 178.712 158.588C178.809 158.685 178.863 158.816 178.863 158.953C178.863 159.09 178.809 159.222 178.712 159.319C178.615 159.416 178.483 159.47 178.346 159.47Z" fill="#E6E6E6"/>
                        <path id="Vector_23" d="M172.283 149.649H38.4862C36.7736 149.649 35.1311 148.968 33.9201 147.756C32.7091 146.544 32.0288 144.901 32.0288 143.187C32.0288 141.474 32.7091 139.83 33.9201 138.618C35.1311 137.407 36.7736 136.726 38.4862 136.726H172.283C173.996 136.726 175.639 137.407 176.85 138.618C178.061 139.83 178.741 141.474 178.741 143.187C178.741 144.901 178.061 146.544 176.85 147.756C175.639 148.968 173.996 149.649 172.283 149.649V149.649ZM38.4862 137.76C37.0476 137.76 35.6679 138.331 34.6507 139.349C33.6335 140.367 33.062 141.748 33.062 143.187C33.062 144.627 33.6335 146.007 34.6507 147.025C35.6679 148.043 37.0476 148.615 38.4862 148.615H172.283C173.722 148.615 175.102 148.043 176.119 147.025C177.136 146.007 177.708 144.627 177.708 143.187C177.708 141.748 177.136 140.367 176.119 139.349C175.102 138.331 173.722 137.76 172.283 137.76H38.4862Z" fill="#3F3D56"/>
                    </g>
                    <path id="prog1" d="M127.34 52.2101H38.6679C37.0731 52.2306 35.5297 51.6458 34.3485 50.5735C33.1672 49.5012 32.4357 48.0209 32.3013 46.4307C32.2438 45.5825 32.3611 44.7315 32.6458 43.9305C32.9306 43.1295 33.3768 42.3956 33.9567 41.7743C34.5366 41.153 35.2379 40.6575 36.0171 40.3186C36.7962 39.9797 37.6367 39.8046 38.4863 39.8041H127.34C128.984 39.8041 130.561 40.4577 131.724 41.6209C132.886 42.7842 133.539 44.362 133.539 46.0071C133.539 47.6523 132.886 49.23 131.724 50.3933C130.561 51.5566 128.984 52.2101 127.34 52.2101H127.34Z" fill="#056D65"/>
                    <path id="prog2" d="M87.5625 100.8H38.6679C37.0731 100.821 35.5297 100.236 34.3485 99.1636C33.1672 98.0912 32.4357 96.611 32.3013 95.0207C32.2438 94.1725 32.3611 93.3215 32.6458 92.5205C32.9306 91.7196 33.3768 90.9857 33.9567 90.3644C34.5366 89.743 35.2379 89.2476 36.0171 88.9087C36.7962 88.5698 37.6367 88.3946 38.4863 88.3942H87.5625C89.2066 88.3942 90.7834 89.0477 91.946 90.211C93.1085 91.3743 93.7616 92.952 93.7616 94.5972C93.7616 96.2423 93.1085 97.82 91.946 98.9833C90.7834 100.147 89.2066 100.8 87.5625 100.8H87.5625Z" fill="#056D65"/>
                    <path id="prog3" d="M153.17 149.39H38.6679C37.0731 149.411 35.5297 148.826 34.3485 147.754C33.1672 146.681 32.4357 145.201 32.3013 143.611C32.2438 142.763 32.3611 141.912 32.6458 141.111C32.9306 140.31 33.3768 139.576 33.9567 138.954C34.5366 138.333 35.2379 137.838 36.0171 137.499C36.7962 137.16 37.6367 136.985 38.4863 136.984H153.17C154.814 136.984 156.391 137.638 157.553 138.801C158.716 139.964 159.369 141.542 159.369 143.187C159.369 144.832 158.716 146.41 157.553 147.573C156.391 148.737 154.814 149.39 153.17 149.39H153.17Z" fill="#056D65"/>
                    <g id="arr1">
                        <path id="Vector_24" d="M118.927 45.9052H117.894V6.08047H192.622V7.11431H118.927V45.9052Z" fill="#CCCCCC"/>
                        <path id="Vector_25" d="M195.816 12.35C199.224 12.35 201.987 9.58539 201.987 6.17502C201.987 2.76465 199.224 0 195.816 0C192.408 0 189.645 2.76465 189.645 6.17502C189.645 9.58539 192.408 12.35 195.816 12.35Z" fill="#CCCCCC"/>
                    </g>
                    <g id="arr2">
                        <path id="Vector_26" d="M83.0607 94.4952H84.0938V71.7287H9.36523V72.7626H83.0607V94.4952Z" fill="#CCCCCC"/>
                        <path id="Vector_27" d="M6.17115 77.9983C9.57939 77.9983 12.3423 75.2336 12.3423 71.8233C12.3423 68.4129 9.57939 65.6483 6.17115 65.6483C2.76292 65.6483 0 68.4129 0 71.8233C0 75.2336 2.76292 77.9983 6.17115 77.9983Z" fill="#CCCCCC"/>
                    </g>
                    <g id="arr3">
                        <path id="Vector_28" d="M153.021 144.84H151.988V173.293H215.352V172.259H153.021V144.84Z" fill="#CCCCCC"/>
                        <path id="Vector_29" d="M218.546 179.373C221.954 179.373 224.717 176.608 224.717 173.198C224.717 169.788 221.954 167.023 218.546 167.023C215.138 167.023 212.375 169.788 212.375 173.198C212.375 176.608 215.138 179.373 218.546 179.373Z" fill="#CCCCCC"/>
                    </g>
                </g>
                <defs>
                    <clipPath id="clip0_101_35">
                        <rect width="362" height="309" fill="white"/>
                    </clipPath>
                </defs>
            </svg>


        </div>
    </>)
}